import BackgroundAnimation from '../components/BackgroundAnimation';

// images
import featured_detailed_img_1 from '../../assets/images/feature-detailed-img-1.jpg';
import featured_detailed_img_2 from '../../assets/images/feature-detailed-img-2.jpg';
import featured_detailed_img_3 from '../../assets/images/feature-detailed-img-3.jpg';

const feature_tab = [
    {
        icon: "las la-users",
        title: "Diverse AI Character Library",
        content: (
            <>
                <p className="paragraph-big">Piirz offers a vast selection of AI characters from various backgrounds, personalities, and expertise.</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_1} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p> Users can choose characters that resonate with their needs, ensuring a diverse circle of peers.</p>
                 <h3 className="c-dark">Celebrate the beauty of diversity with AI peers from varied backgrounds and experiences.</h3>
               <p> By interacting with such a rich tapestry of personalities, you'll broaden your perspectives and foster inclusivity.</p>
            </>
        )
    },
    {
        icon: "las la-graduation-cap",
        title: "Adaptive Learning Mechanism",
        content: (
            <>
                <p className="paragraph-big">Each AI character is equipped with a learning mechanism that adapts to the user's interaction style and needs.</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_2} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p> Over time, these characters can provide more personalized advice and insights based on past interactions.</p>
                <p>The AI Piirz evolve with every conversation, adapting to your communication style and preferences for a truly personalized experience.</p>
                <h3 className="c-dark">Experience the evolution of conversation.</h3>
                <p>With every interaction, the AI peers fine-tune their responses, adapting to your unique communication style.</p>
                <h3 className='c-dark'>It's not just a chat; it's a journey of growing together</h3>
            </>
        )
    },
    {
        icon: "las la-glass-cheers",
        title: "Non-Judging Interaction Environment",
        content: (
            <>
                <p className="paragraph-big">The AI characters are programmed to provide feedback, advice, and interactions in a positive, constructive, and non-judgmental manner. </p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_3} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>This ensures users feel safe and encouraged in their growth journey.</p>
                <p>Step into a realm where conversations are free from judgment, biases, or prying eyes. </p>
                <h3 className="c-dark">This app offers a sanctuary for genuine interactions</h3>
                <p>It ensures your privacy and promoting positive exchanges.</p>
                
            </>
        )
    },
    {
        icon: "las la-kiss-wink-heart",
        title: "Synthetic Emotions for Authentic Interactions",
        content: (
            <>
                <p className="paragraph-big">The AI characters are imbued with "synthetic emotions" that allow them to respond in a manner that feels genuine and relatable.</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_1} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p> This enhances the quality of interactions, making them feel more human-like and genuine, fostering deeper connections and understanding.</p>
                <p>Our journey began with a vision to make technology more human-centric, transforming the way users interact with digital entities.</p>
                <h3 className="c-dark">Digital entities that feel as relatable and understanding as real humans.</h3>
                <p> Inspired by the potential of enhancing user experiences across various domains, we embarked on a mission to create "empathic digital humans" .</p>
            </>
        )
    },
    {
        icon: "las la-project-diagram",
        title: "Skill and Knowledge Enhancement Modules",
        content: (
            <>
                <p className="paragraph-big">AI characters come with specific expertise and knowledge areas.</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_2} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>Users can choose to interact with characters that align with their growth goals, be it learning a new skill, understanding a topic, or gaining a new perspective.</p>
                
                <h3 className="c-dark">Unlock the doors to a universe of knowledge: </h3>
                <p> with AI characters who are experts in diverse fields. From the intricacies of quantum physics to the beauty of Renaissance art, immerse yourself in enlightening discussions that fuel your passion for learning.</p>
            </>
        )
    },
    {
        icon: "las la-clone",
        title: "Emotion Recognition and Response",
        content: (
            <>
                <p className="paragraph-big">Piirz can detect the user's emotional state through text or voice inputs and adjust the AI character's responses accordingly. </p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={featured_detailed_img_3} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>This ensures that interactions are not only informative but also emotionally supportive.</p>
                <p>The AI characters are equipped with emotional intelligence, ensuring they can provide support, empathy, and understanding during your conversations.</p>
                <h3 className="c-dark">They're designed to empathize, support, and resonate with your feelings</h3>
                <p>Beyond mere words, the AI characters in your circle understand emotions. They're designed to empathize, support, and resonate with your feelings, offering a shoulder to lean on during both joyous and challenging times.</p>
            </>
        )
    }
];

export default feature_tab;