import { Link } from "react-router-dom";

const policy = [
   
    {
        icon: "las la-question-circle",
        title: "Privacy Policy",
        content: (
            <>
                <h3 className="c-dark">Privacy Policy for PiirZ.com</h3>
                <h3 className="c-dark">  1. Introduction</h3>
<p>Welcome to PiirZ.com, owned and operated by Piirz LTD, based in London. This Privacy Policy outlines our commitment to protecting the privacy of our users. By using our website, you agree to the terms of this Privacy Policy.</p>

<h3 className="c-dark">2. Data Collection</h3>
<p>When you register on our website, we collect the following information:</p>
<ul className="icon-list icon-list-1">
<li><span> Name</span></li>
<li><span>Email</span></li>
<li><span>Country/City</span></li>
</ul>
<p>This data is collected to ensure a tailored experience and to avoid proposing services unavailable in specific territories.
By using piirz.com website and related solutions on any media, users grant Piirz LTD and its partners full rights to conduct an anonymized audit of interactions. We may also carry out business intelligence activities on the collected data to improve the user experience and the services offered.
</p>

<h3 className="c-dark">3. Cookies and Analytics</h3>
<p>We use cookies and analytics tools, including Google Analytics and Meta Pixel, to enhance your user experience and improve our services. These tools help us understand user behavior and optimize our offerings accordingly.
</p>
<h3 className="c-dark">4. Payment Gateway Integration</h3>
<p>We have integrated with Stripe as our payment gateway. While we ensure the security of your transactions, the specifics of the payment processing are governed by Stripe's Privacy Policy.
</p>
<h3 className="c-dark">5. Newsletter Subscription</h3>
<p>Users have the option to subscribe to our newsletter. Clear instructions on how to unsubscribe will be provided in every email. If you wish to opt-out, you can do so at any time.
</p>
<h3 className="c-dark">6. User Rights</h3>
<p>As a user, you have the right to:</p>
<ul className="icon-list icon-list-1">
<li><span>Access your data</span></li>
<li><span>Request modifications to your data</span></li>
<li><span> Request deletion of your data</span></li>
</ul>
<p>Your data is stored for the duration of the service. If you cancel your profile, your data will be automatically deleted after 60 days.
</p>
<h3 className="c-dark">7. Age and Regional Restrictions</h3>
<p>Users are restricted from using the website if:</p>
<ul className="icon-list icon-list-2">
<li><span>Their residing country prohibits the use of our proposed tools.</span></li>
<li><span> Their residing country restricts access to interactions with AI.</span></li>
<li><span>Using the site violates local law.</span></li>
</ul>
<h3 className="c-dark">8. Data Sharing</h3>

<p>Neither PiirZ.com nor Piirz LTD shares your information with third parties, apart from the mentioned integrations.
</p>
<h3 className="c-dark">9. Data Storage</h3>
<p>All user data is stored in Europe, in compliance with GDPR regulations.</p>

<h3 className="c-dark">10. Data Breach Protocol</h3>
<p>In the event of a data breach, we will:</p>
<ul className="icon-list icon-list-2">
    <li><span>Alert all affected users.</span></li>
    <li><span>Advise users to change their passwords.</span></li>
    <li><span>Provide a detailed 'post mortem' report of the breach once our assessment is complete.</span></li>
</ul>
<h3 className="c-dark">11. Contacting the Data Protection Officer (DPO)</h3>
<p>For any privacy-related inquiries, you can contact our DPO at dpo@harmonya.online.</p>


<h3 className="c-dark">Changes to This Privacy Policy</h3>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
</p>
<p>Thank you for trusting PiirZ.com with your information. We are committed to safeguarding your data and upholding the highest standards of data privacy. If you have any questions about this Privacy Policy, please contact us.
                </p>
            </>
        )
    },
    {
        icon: "las la-headset",
        title: "Get Help",
        content: (
            <>
                <p className="paragraph-big">Join us on Discord </p>
                <h3 className="c-dark">Get Assistance and Support</h3>
                <p>We're thrilled to announce a fantastic new addition to our support and feedback channels – the official Piirz Discord Bug Reporting and Support Server! 🎉</p>

<p>In our ongoing efforts to improve your experience and provide better assistance, we understand that clear communication is key. That's why we've decided to create a dedicated space where you can report any bugs, seek support, and engage with our awesome community of users.</p>

<p>🐞 Bug Reporting: Found a pesky bug or glitch while using Piirz? Don't worry! Our Discord server will soon be your go-to place to report these issues directly to our development team. Your feedback will be invaluable in helping us squash those bugs.</p>

<p>💡 Support & Assistance: Need help or have questions about Piirz? Our support team will be at your service in the server to provide prompt assistance. Whether it's troubleshooting, guidance, or just a friendly chat, we've got you covered.</p>

<p>🤝 Community Engagement: Our Discord server will also be a hub for our fantastic Piirz community. You can connect with fellow users, share your experiences, and even participate in exciting discussions and events.</p>

<p>Stay tuned for the official launch of our Discord Bug Reporting and Support Server. We can't wait to have you on board!</p>

<p>We value your input and believe that this new platform will foster a stronger and more engaging relationship between our team and our users. Together, we'll continue to make Piirz even better.</p>

<p>Thank you for your continued support, and get ready to join us on <Link to="https://discord.gg/7k9xWaXy" style={{cursor: 'pointer'}}>Discord</Link> ! 🚀</p>
            </>
        )
    },
    {
        icon: "las la-bug",
        title: "Report a Bug",
        content: (
            <>
                <p className="paragraph-big">Join us on Discord</p>
                <h3 className="c-dark">Report a bug</h3>
                <p>We're thrilled to announce a fantastic new addition to our support and feedback channels – the official Piirz Discord Bug Reporting and Support Server! 🎉</p>

<p>In our ongoing efforts to improve your experience and provide better assistance, we understand that clear communication is key. That's why we've decided to create a dedicated space where you can report any bugs, seek support, and engage with our awesome community of users.</p>

<p>🐞 Bug Reporting: Found a pesky bug or glitch while using Piirz? Don't worry! Our Discord server will soon be your go-to place to report these issues directly to our development team. Your feedback will be invaluable in helping us squash those bugs.</p>

<p>💡 Support & Assistance: Need help or have questions about Piirz? Our support team will be at your service in the server to provide prompt assistance. Whether it's troubleshooting, guidance, or just a friendly chat, we've got you covered.</p>

<p>🤝 Community Engagement: Our Discord server will also be a hub for our fantastic Piirz community. You can connect with fellow users, share your experiences, and even participate in exciting discussions and events.</p>

<p>Stay tuned for the official launch of our Discord Bug Reporting and Support Server. We can't wait to have you on board!</p>

<p>We value your input and believe that this new platform will foster a stronger and more engaging relationship between our team and our users. Together, we'll continue to make Piirz even better.</p>

<p>Thank you for your continued support, and get ready to join us on <Link to="https://discord.gg/7k9xWaXy">Discord</Link>! 🚀</p>
            </>
        )
    },
    
];

export default policy;