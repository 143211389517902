import React from 'react';

// components
import DownloadButton from '../components/DownloadButton';
import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';
import Button from '../components/Button';
import PricingSlider from '../components/PricingSlider';
import TestimonialSlider from '../components/TestimonialSlider';
import PiirzSlider2 from '../components/PiirzSlider2';
import ScreenSlider from '../components/ScreenSlider';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import BlogSingle from '../components/BlogSingle';
import BackgroundAnimation from '../components/BackgroundAnimation';
import VideoLightBox from '../components/VideoLightBox';
import ImageSlider from '../components/ImageSlider';

// images
import video_img from '../../assets/images/video-img.jpg';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import background_image_2 from '../../assets/images/patterns/pattern-2.jpg';
import feature_phone_1 from '../../assets/images/feature-section-1-phone.png';
import feature_image_1 from '../../assets/images/feature-section-1-img.jpg';
import feature_phone_2 from '../../assets/images/feature-section-2-phone.png';
import feature_image_2 from '../../assets/images/feature-section-2-img.jpg';

// data
import blog from '../data/blog';
import features from '../data/features';
import faq from '../data/faq';

const Index = () => {

    

    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1 feature-section feature-section-0">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content">
                                    <h1 className="c-dark">Hey, welcome to your future AI Peer's circle!</h1>
                                    <p className="large c-grey">Feel the warmth of friendship, powered by cutting-edge tech.</p>
                                    <DownloadButton color='c-white'/>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <div className="feature-section-image">
                                        <img src={feature_image_1} className="image" alt='-' />
                                        <img src={feature_phone_1} className="phone" alt="phone" />
                                        <div className="background-pattern background-pattern-radius">
                                            <BackgroundAnimation/>
                                            <div className="background-pattern-gradient"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    heading='Introducing PIIRZ:'
                                    subHeading='An Exclusive Circle of AI Friends Awaiting You.'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            { features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* video - start */}
            <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-video"></i>
                                            <h5>our presentation</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Discover the World of Empathic Digital Humans</h1>
                                        </div>
                                    </div>
                                    <Button
                                        to="/pre-register"
                                        content="Pre-register"
                                        type="button-1"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img} width='471' height='472' alt="drop-shadow" />
                                        
                                        <div className="play">
                                            <VideoLightBox URL='https://youtu.be/udHpWjWtBKY' />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video - end */}

            {/* feature section - start */}
            <div className="feature-section feature-section-0 feature-section-spacing-1">
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        icon='las la-cog'
                                        heading='Comparison'
                                        subHeading="Traditional AI vs. Obsidian's Digital Humans" />
                                    
<p className="c-grey"> a sample of what we call SEEDHs, or “Synthetic empathy enabled digital humans”, is a simple answer you can get by saying: <br />“Hi, I’m Valeria, how are you?”
</p>
                                    <div className="icon-text-group">
                                        <div className="icon-text">
                                            <i className="las la-microchip"></i>
                                            <h4 className="c-dark">ChatGPT's Response</h4>
                                            <p className="c-grey">I'm here to help and answer your questions!Is there something specific you'd like to know or discuss?</p>
                                        </div>
                                        <div className="icon-text">
                                            <i className="las la-laugh-squint"></i>
                                            <h4 className="c-dark"> Frankie's Response</h4>
                                            <p className="c-grey">OH MY GOD,IT'S SO NICE TO MEET YOU, VALERIA!!! faints...I know this amazing little bakery...</p>
                                        </div>
                                        
                                    </div>
                                    
                                    <Button
                                        to="/pre-register"
                                        content="Pre-register"
                                        type="button-2"
                                    />
                                    <p className="c-grey">Note: <i>Frankie's response showcases what we term as "empathic markers", which are akin to emoticons in chats, enhancing the emotional depth of their replies.</i>
</p>   
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                    <img src={feature_image_1} className="image" alt="feature-fore" />
                                    <img src={feature_phone_1} className="phone" alt="phone" />
                                    <div className="background-pattern background-pattern-radius">
                                        <BackgroundAnimation/>
                                        <div className="background-pattern-gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* feature section - start */}
            <div className="feature-section feature-section-1 feature-section-spacing-2">
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <div className="feature-section-image">
                                    <img src={feature_image_2} className="image" alt="feature-fore" />
                                    <img src={feature_phone_2} className="phone" alt="phone" />
                                    <div className="background-pattern background-pattern-radius-reverse">
                                        <BackgroundAnimation/>
                                        <div className="background-pattern-gradient"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        icon='las la-cog'
                                        heading='Unlock an Exclusive World with Pre-Registration!'
                                        subHeading='Dive into a realm where AI feels more human than ever'
                                    />                           
                                    <div className="icon-text-1-group">
                                        <div className="icon-text-1">
                                            <i className="las la-shoe-prints"></i>
                                            <div>
                                                <h4 className="c-dark">Early Access</h4>
                                                <p className="c-grey">Be the first to experience the magic of PIIRZ.</p>
                                            </div>
                                        </div>
                                        <div className="icon-text-1">
                                            <i className="las la-user-friends"></i>
                                            <div>
                                                <h4 className="c-dark">Priority on AI Friend Choices</h4>
                                                <p className="c-grey">Get the pick of the lot and choose your AI friends before anyone else</p>
                                            </div>
                                        </div>
                                        <div className="icon-text-1">
                                            <i className="las la-seedling"></i>
                                            <div>
                                                <h4 className="c-dark">Free Daily Interaction Slot</h4>
                                                <p className="c-grey">Engage with your AI circle everyday,on us.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        to="/pre-register"
                                        content="Pre-register"
                                        type="button-3"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* pricing section - start 
            <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-tags'
                                    heading='our app rates'
                                    subHeading='Pricing plans for you.'
                                    additionalClasses='center c-white'
                                    mainHeadingClass='c-white'
                                    subHeadingClass='c-white'
                                />
                            </div>
                        </div>
                        <PricingSlider/>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* pricing section - end */}
             {/* piirzsection2 section - start */}
             <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-heart'
                                    heading='our piirz'
                                    subHeading='The Perfect Blend of Tech & Heart'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <PiirzSlider2/>
                        </div>
                    </div>
                </div>
            </div>
            {/* piirzsection2 section - end */}

            {/* testimonial section - 
            <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-comments'
                                    heading='feedbacks'
                                    subHeading='What people are talking about.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <TestimonialSlider/>
                        </div>
                    </div>
                </div>
            </div> start */}
            {/* testimonial section - end */}

            {/* faq section - start */}
            <div className="faq-section">
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-file-alt'
                                    heading='discover'
                                    subHeading='Some frequently asked questions'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-md-8 col-10">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                            className={`accordion-button ${key !== 1 ? 'collapsed' : ''}`}
                                                            type="button" 
                                                            data-bs-toggle="collapse" 
                                                            data-bs-target={`#faq-collapse-${key}`} 
                                                            aria-expanded={key === 1 ? 'true': 'false'}
                                                            aria-controls={`faq-collapse-${key}`}>
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div 
                                                        id={`faq-collapse-${key}`} 
                                                        className={`accordion-collapse collapse ${key === 1 ? 'show' : ''}`}
                                                        aria-labelledby={`faq-${key}`} 
                                                        data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faq section - end */}

            {/* screen section - start */}
            <div className="screen-section">
                <div className="screen-section-wrapper">
                    <ScreenSlider/>
                    {/* screen section bottom - start */}
                    <div className="screen-section-bottom">
                        <div className="screen-section-bottom-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                                        <h1 className="c-white">Soon on your favorite messaging app!</h1>
                                        <h4 className="c-white">
                                            <i className="fas fa-smile" style={{marginRight: '1rem'}}></i>
                                            Piirz: Elegant, Engaging, Exceptional!
                                        </h4>
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                                        <DownloadButton groupType='download-button-1-group' buttonType='download-button-1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* screen section bottom - end */}     
                </div>
                <div className="background-pattern background-pattern-2">
                    <BackgroundAnimation/>
                    <div className="background-pattern-gradient"></div>
                    <div className="background-pattern-bottom">
                        <div className="image" style={{backgroundImage: `url(${background_image_2})`}}></div>
                    </div>
                </div>
            </div>
            {/* screem section - end */}

            {/* blog section 
            <div className="blog-section">
                <div className="blog-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-bullhorn'
                                    heading='recent news'
                                    subHeading='Read latest news from our blog.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                        <div className="row gx-5">
                            {
                                blog.slice(0, 3).map((element, key) => {
                                    return (
                                        <div key={key} className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                            <BlogSingle element={element} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>- start */}
            {/* blog section - end */}

            {/* cta section - start */}
            <div className="cta-section">
                <div className="cta-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-bell'
                                    heading='LIMITED SPOTS AVAILABLE!'
                                    subHeading='Exclusive Pre-Registration for PeerZ'
                                    additionalClasses='center width-71'
                                />
                                <DownloadButton color='c-white'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cta section - end */}

            {/* contact form section - start */}
            <div className="contact-form-section"  id='pre-register'>
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start */}
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* newsletter form - start */}
                                <ContactForm2/>
                                {/* newsletter form - end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default Index;
