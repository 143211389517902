import axios from 'axios';
require('dotenv').config();

const srvConn = async (srvRoute, postData) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'Basic ' +
      Buffer.from(`${process.env.REACT_APP_USER_API}:${process.env.REACT_APP_PWD_API}`).toString(
        'base64'
      ),
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}${srvRoute}`,
      postData,
      { headers: headers }
    );

    if (res) {
      console.log('res', res);
      return { success: true };
    } else {
      return { success: false, error: res.response?.data.error };
    }
  } catch (err) {
    console.log('err', err);
    return { success: false, error: err.response?.data.error };
  }
};

export default srvConn;
