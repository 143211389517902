import {React, useRef, useEffect, useState, useMemo} from 'react';
import parse from 'html-react-parser';

// import Swiper React components
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation } from 'swiper';

import { Link } from 'react-router-dom';

import dataPiirz from '../data/piirz-personas'

const PiirzSlider = () => {
    
    
    

    
    
    return (
        <div className="piirz-slider">
            <Swiper
                modules={[Navigation]}
                navigation={{
                    nextEl: '.piirz-slider-navigation-next',
                    prevEl: '.piirz-slider-navigation-prev'
                }}
                loop={true}
                centeredSlides={true}
                initialSlide={1}
                breakpoints={{
                    0: {
                        slidesPerView: 1.5,
                        spaceBetween: 30
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    }
                }}
            >

                
                {
                    dataPiirz.map((member, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <div className="piirz-single drop-shadow-piirz-1">
                                    <div className="piirz-single-wrapper">
                                        <div className='body'>
                                        <div className='front'>
                                            <div className="image">
                                                <div className="image-wrapper">
                                                    <div className="image-inner">
                                                        <img src={member.image} alt="piirz-member" />
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>{member.name}</h3>
                                            <p>{member.designation}</p>
                                           
                                        </div>
                                        <div className="content d-flex flex-column align-items-center justify-content-center">
                                            <div>
                                            <h3>{member.name}</h3>
                                            <p >{(member.description) ? parse(member.description) : (null)} </p>
                                            <div className="social social-1">
                                                <ul>
                                                    {
                                                        member.social.map((single, innerkey) => {
                                                            return (
                                                                <li key={innerkey}>
                                                                    <Link to={single.to}>
                                                                        <i className={single.icon}></i>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                    
                
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <div className="piirz-slider-navigation slider-navigation">
                <div className="piirz-slider-navigation-prev">
                    <i className="las la-long-arrow-alt-left"></i>
                </div>
                <div className="piirz-slider-navigation-next">
                    <i className="las la-long-arrow-alt-right"></i>
                </div>
            </div>
        </div>
    );
};

export default PiirzSlider;
