import React from 'react';

// import Swiper React components
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';


import dataPiirz from '../data/piirz-personas';

SwiperCore.use([Autoplay]);



const ImageSlider = () => {

    

    return (
        <div className="instagram-slider">
            <Swiper
                autoplay={{
                    delay: 1500
                }}
                speed={1500}
                spaceBetween={4}
                centeredSlides={true}
                loop={true}
                initialSlide={3}
                breakpoints={{
                    0: {
                        slidesPerView: 2.2
                    },
                    768: {
                        slidesPerView: 3.2
                    },
                    992: {
                        slidesPerView: 6
                    }
                }}
            >
                
                {
                    dataPiirz.map(function(element) {
                        
                      //if (typeof element.images !== 'undefined' && element.images !== null && element.images.length > 0) {
                           // console.log("element images",element.images)
                     return   element.images.map((el, key) => {
                          // console.log("image - ", el.image)
                       return  (
                        <SwiperSlide key={key}>
                                <div className="instagram-slide" key={key}>
                                    <img src={el.image} alt="instagram-slide" />
                                </div>
                            </SwiperSlide>
                         )
                        })
                   // }
                    })
                }
            </Swiper>
        </div>
    );
};

export default ImageSlider;
