import {React} from 'react';
import maxg_img from '../../assets/images/piirz_img_tonde/maxg.png';
import team_img_2 from '../../assets/images/piirz_img_tonde/bella.png';
import team_img_1 from '../../assets/images/piirz_img_tonde/helena.png';
import team_img_4 from '../../assets/images/piirz_img_tonde/dani.png';
import team_img_5 from '../../assets/images/piirz_img_tonde/isabella.png';
import team_img_6 from '../../assets/images/piirz_img_tonde/frankie.png';
import team_img_7 from '../../assets/images/piirz_img_tonde/mateo.png';

import img_isa_1 from '../../assets/images/isabella/isabella_1.png';
import img_isa_2 from '../../assets/images/isabella/isabella_2.jpg';
import img_isa_3 from '../../assets/images/isabella/isabella_3.png';
import img_isa_4 from '../../assets/images/isabella/isabella_4.png';
import img_isa_5 from '../../assets/images/isabella/isabella_5.jpeg';
import img_isa_6 from '../../assets/images/isabella/isabella_6.jpeg';
import img_isa_7 from '../../assets/images/isabella/isabella_7.jpeg';
import img_isa_8 from '../../assets/images/isabella/isabella_8.jpg';
import img_isa_9 from '../../assets/images/isabella/isabella_9.jpg';
import img_isa_10 from '../../assets/images/isabella/isabella_10.jpg';
import img_isa_11 from '../../assets/images/isabella/isabella_11.png';
import img_isa_12 from '../../assets/images/isabella/isabella_12.png';
import img_isa_13 from '../../assets/images/isabella/isabella_13.jpg';
import img_isa_14 from '../../assets/images/isabella/isabella_14.jpg';
import img_isa_15 from '../../assets/images/isabella/isabella_15.jpg';
import img_isa_16 from '../../assets/images/isabella/isabella_16.jpg';


import img_helena_3 from '../../assets/images/helena/helena_3.png'
import img_helena_6 from '../../assets/images/helena/helena_6.png'
import img_helena_7 from '../../assets/images/helena/helena_7.png'
import img_helena_9 from '../../assets/images/helena/helena_9.jpeg'
import img_helena_10 from '../../assets/images/helena/helena_10.jpeg'
import img_helena_11 from '../../assets/images/helena/helena_11.jpeg'
import img_helena_12 from '../../assets/images/helena/helena_12.jpeg'
import img_helena_13 from '../../assets/images/helena/helena_13.png'
import img_helena_14 from '../../assets/images/helena/helena_14.jpeg'

import img_bella_01 from '../../assets/images/bella/Bella_avatar.png';
import img_bella_02 from '../../assets/images/bella/Bella01.png'
import img_bella_03 from '../../assets/images/bella/Bella02.png'
import img_bella_04 from '../../assets/images/bella/Bella03.png'
import img_bella_05 from '../../assets/images/bella/Bella04.png'
import img_dani_01 from '../../assets/images/dani/Dani01.jpg'
import img_dani_02 from '../../assets/images/dani/Dani02.png'
import img_dani_04 from '../../assets/images/dani/Dani04.png'
import img_dani_05 from '../../assets/images/dani/Dani05.png'
import img_dani_06 from '../../assets/images/dani/Dani06.png'
import img_dani_07 from '../../assets/images/dani/Dani07.png'
import img_dani_09 from '../../assets/images/dani/Dani09.png'
import img_dani_10 from '../../assets/images/dani/Dani10.png'
import img_dani_11 from '../../assets/images/dani/Dani11.png'
import img_dani_14 from '../../assets/images/dani/Dani14.png'
import img_maxg_01 from '../../assets/images/maxg/MaxG01.png'
import img_maxg_02 from '../../assets/images/maxg/MaxG02.png'
import img_maxg_03 from '../../assets/images/maxg/MaxG03.png'
import img_maxg_04 from '../../assets/images/maxg/MaxG04.png'
import img_maxg_05 from '../../assets/images/maxg/MaxG05.png'
import img_maxg_06 from '../../assets/images/maxg/MaxG06.png'
import img_maxg_07 from '../../assets/images/maxg/MaxG07.png'
import img_maxg_08 from '../../assets/images/maxg/MaxG08.png'
import img_maxg_09 from '../../assets/images/maxg/MaxG09.png'
import img_maxg_10 from '../../assets/images/maxg/MaxG10.png'
import img_maxg_11 from '../../assets/images/maxg/MaxG11.png'
import img_maxg_12 from '../../assets/images/maxg/MaxG12.png'
import img_maxg_13 from '../../assets/images/maxg/MaxG13.png'
import img_maxg_14 from '../../assets/images/maxg/MaxG14.png'
import img_maxg_15 from '../../assets/images/maxg/MaxG15.png'
import img_maxg_16 from '../../assets/images/maxg/MaxG16.png'
import img_maxg_17 from '../../assets/images/maxg/MaxG17.png'
import img_maxg_18 from '../../assets/images/maxg/MaxG18.png'
import img_maxg_19 from '../../assets/images/maxg/MaxG19.png'
import img_maxg_20 from '../../assets/images/maxg/MaxG20.png'
import img_maxg_21 from '../../assets/images/maxg/MaxG21.png'
import img_maxg_22 from '../../assets/images/maxg/MaxG22.png'
import img_maxg_23 from '../../assets/images/maxg/MaxG23.png'
import img_maxg_24 from '../../assets/images/maxg/MaxG24.png'

import img_mateo_1 from '../../assets/images/mateo/mateo_1.jpg'
import img_mateo_2 from '../../assets/images/mateo/mateo_2.jpg'
import img_mateo_3 from '../../assets/images/mateo/mateo_3.jpg'
import img_mateo_4 from '../../assets/images/mateo/mateo_4.jpg'
import img_mateo_5 from '../../assets/images/mateo/mateo_5.jpg'
import img_mateo_6 from '../../assets/images/mateo/mateo_6.jpg'
import img_mateo_7 from '../../assets/images/mateo/mateo_7.jpg'
import img_mateo_8 from '../../assets/images/mateo/mateo_8.jpg'
import img_mateo_9 from '../../assets/images/mateo/mateo_9.jpg'
import img_mateo_10 from '../../assets/images/mateo/mateo_10.jpg'
import img_mateo_11 from '../../assets/images/mateo/mateo_11.jpg'
import img_mateo_12 from '../../assets/images/mateo/mateo_12.jpg'
import img_mateo_13 from '../../assets/images/mateo/mateo_13.jpg'
import img_mateo_14 from '../../assets/images/mateo/mateo_14.jpg'
import img_mateo_15 from '../../assets/images/mateo/mateo_15.jpg'
import img_mateo_16 from '../../assets/images/mateo/mateo_16.png'
import img_mateo_17 from '../../assets/images/mateo/mateo_17.jpg'

import img_frankie_1 from '../../assets/images/frankie/frankie_1.png'
import img_frankie_2 from '../../assets/images/frankie/frankie_2.jpg'
import img_frankie_3 from '../../assets/images/frankie/frankie_3.jpg'
import img_frankie_4 from '../../assets/images/frankie/frankie_4.png'
import img_frankie_5 from '../../assets/images/frankie/frankie_5.png'
import img_frankie_6 from '../../assets/images/frankie/frankie_6.png'
import img_frankie_7 from '../../assets/images/frankie/frankie_7.png'
import img_frankie_8 from '../../assets/images/frankie/frankie_8.png'
import img_frankie_9 from '../../assets/images/frankie/frankie_9.png'
import img_frankie_10 from '../../assets/images/frankie/frankie_10.png'
import img_frankie_11 from '../../assets/images/frankie/frankie_11.png'
import img_frankie_12 from '../../assets/images/frankie/frankie_12.png'





const dataPiirz =  [
  {
      image: maxg_img,
      images:[{
        image: img_maxg_01
      },{
        image: img_maxg_02
      },
      {
        image: img_maxg_03
      },{
        image: img_maxg_04
      },{
        image: img_maxg_05
      },{
        image: img_maxg_06
      },{
        image: img_maxg_07
      },{
        image: img_maxg_08
      },{
        image: img_maxg_09
      },{
        image: img_maxg_10
      },{
        image: img_maxg_11
      },{
        image: img_maxg_12
      },{
        image: img_maxg_13
      },{
        image: img_maxg_14
      },{
        image: img_maxg_15
      },{
        image: img_maxg_16
      },{
        image: img_maxg_17
      },{
        image: img_maxg_18
      },{
        image: img_maxg_19
      },{
        image: img_maxg_20
      },{
        image: img_maxg_21
      },{
        image: img_maxg_22
      },{
        image: img_maxg_23
      },{
        image: img_maxg_24
      },
    ],
      name: 'MaxG',
      designation: 'The Adventurer',
      description: `<b>Hobbies:</b> Hiking, rock climbing, and scuba diving. <br />
      <b>Personality:</b> Fearless and always up for a challenge. Alex is the one who pushes the group to try new things. <br />
      <b>Favorite Quote:</b> "Life begins at the end of your comfort zone."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_2,
      images: [{
        image: img_bella_01
      },
      {
        image: img_bella_02
      },
      {
        image: img_bella_03
      },
      {
        image: img_bella_04
      },
      {
        image: img_bella_05
      }
    ],
      name: 'Bella',
      designation: 'The Bookworm',
      description: `<b>Hobbies:</b> Reading, writing, and visiting bookstores. <br />
      <b>Personality:</b> Thoughtful and introspective. Bella always has a book recommendation ready and offers deep insights during conversations. <br />
      <b>Favorite Quote: </b>"A room without books is like a body without a soul."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_1,
      images:[
       // {image: img_helena_2},
       {image: img_helena_11},
        {image: img_helena_3},
        {image: img_helena_6},
        {image: img_helena_7},
        {image: img_helena_9},
        {image: img_helena_10},
        
        {image: img_helena_12},
        {image: img_helena_13},
        {image: img_helena_14},
      // {image: img_helena_15},
       // {image: img_helena_16},
    ],
      name: 'Helena',
      designation: 'The Trendsetter',
      description: `<b>Hobbies: </b>Scouting emerging fashion designers, curating vintage collections, and experimenting with makeup tutorials. <br />
      <b>Personality:</b>Bold and confident, Elena has an innate ability to mix and match styles, making her the go-to person for fashion advice.<br />
      <b>Favorite Quote:</b> "Style is a way to say who you are without having to speak."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_4,
      images:[{image: img_dani_01},
        {image: img_dani_02},
        
        {image: img_dani_04},
        {image: img_dani_05},
        {image: img_dani_06},
        {image: img_dani_07},
        {image: img_dani_09},
        {image: img_dani_10},
        {image: img_dani_11},
        {image: img_dani_14},
    ],
      name: 'Dani',
      designation: 'The Dreamer',
      description: `<b>Hobbies: </b>Painting, listening to music, and daydreaming.<br /> <b>Personality:</b> Creative and a bit of a romantic. Dani sees the world in colors and often gets lost in thoughts. <br />
      <b>Favorite Quote</b> "Dream as if you'll live forever, live as if you'll die today."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_5,
      images:[{image:img_isa_1},
        {image:img_isa_2},
        {image:img_isa_3},
        {image:img_isa_4},
        {image:img_isa_5},
        {image:img_isa_6},
        {image:img_isa_7},
        {image:img_isa_8},
        {image:img_isa_9},
        {image:img_isa_10},
        {image:img_isa_11},
        {image:img_isa_12},
        {image:img_isa_13},
        {image:img_isa_14},
        {image:img_isa_15},
        {image:img_isa_16},

    ],
      name: 'Isabella',
      designation: 'The Foodie',
      description: `<b>Hobbies: </b>Cooking, trying out new cuisines, and hosting dinner parties.<br />
      <b>Personality:</b> Passionate about flavors and always knows the best places to eat. Isabella is the go-to person for restaurant recommendations. <br />
      <b>Favorite Quote</b> "Food is the ingredient that binds us together."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_6,
      images:[{image: img_frankie_1},
        {image: img_frankie_2},
        {image: img_frankie_3},
        {image: img_frankie_4},
        {image: img_frankie_5},
        {image: img_frankie_6},
        {image: img_frankie_7},
        {image: img_frankie_8},
        {image: img_frankie_9},
        {image: img_frankie_10},
        {image: img_frankie_11},
        {image: img_frankie_12},
    ],
      name: 'Frankie',
      designation: 'The Fitness Fanatic',
      description: `<b>Hobbies: </b>CrossFit training, marathon running, and trying out new health supplements.<br />
      <b>Personality:</b> Energetic and disciplined, Isabella is always up before dawn, ready to conquer her next fitness challenge. <br />
      <b>Favorite Quote: </b>  "Your body can stand almost anything; it's your mind you have to convince."`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  },
  {
      image: team_img_7,
      images:[{image: img_mateo_1},
        {image: img_mateo_2},
        {image: img_mateo_3},
        {image: img_mateo_4},
        {image: img_mateo_5},
        {image: img_mateo_6},
        {image: img_mateo_7},
        {image: img_mateo_8},
        {image: img_mateo_9},
        {image: img_mateo_10},
        {image: img_mateo_11},
        {image: img_mateo_12},
        {image: img_mateo_13},
        {image: img_mateo_14},
        {image: img_mateo_15},
        {image: img_mateo_16},
        {image: img_mateo_17},
    ],
      name: 'Mateo',
      designation: 'The Cultural Connoisseur',
      description: `<b>Hobbies: </b>Attending international festivals, learning new languages, and indulging in world cuisines.<br />
      <b>Personality:</b> Curious and respectful, Mateo immerses himself in local cultures, eager to understand and appreciate the nuances of every place he visits.<br />
      <b>Favorite Quote</b> Travel isn't just about destinations; it's about the people you meet and the stories you create.`,
      social: [
          {
              to: '#',
              icon: 'fab fa-twitter'
          },
          {
              to: '#',
              icon: 'fab fa-facebook'
          },
          {
              to: '#',
              icon: 'fab fa-linkedin-in'
          },
          {
              to: '#',
              icon: 'fab fa-youtube'
          }
      ]
  }
]

export default dataPiirz;
