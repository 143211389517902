import React from 'react';
import VideoLightBox2 from './VideoLightBox2';

const DownloadButton = ({ groupType, buttonType, color }) => {

    const scroll = () => {
        const section = document.querySelector( '#pre-register' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      };
    return (
        <div className={`download-button-group ${groupType}`}>
            <a href="#" onClick={scroll} className={`download-button download-button-google ${buttonType}`}>
                <div className={`download-button-inner ${color}`}>
                    <div className={`download-button-icon c-orange ${color}`}>
                        <i className="fa fa-pen-nib"></i>
                    </div>
                    <div className="download-button-content">
                         <h5 className={`c-grey upper ls-1 ${color}`}>Jump in!</h5>
                        <h3 className={`c-dark ls-2 ${color}`}>Pre-Register</h3>
                    </div>
                </div>
            </a>
           
            <VideoLightBox2 URL='https://youtu.be/udHpWjWtBKY' color={color} buttonType={buttonType} />
                                        
        
            
        </div>
    );
};

export default DownloadButton;
