import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionHeading from './SectionHeading';
import countries from "i18n-iso-countries";
import { Multiselect } from 'multiselect-react-dropdown';
import validationInput from './helpers/validationInput';
import srvConn from './helpers/srvConn';
import logo from '../../assets/images/logo.png';

//import dotenv from 'dotenv';


// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";

//require('dotenv').config();

const ContactForm1 = () => {
    //dotenv.config();
  
    const plainArray = ["English", "Español", "Français", "Italiano", "Deutsch", "Polski", "Português"]
        

    const [name, setName] = useState('');
    const [privacyStatus, setPrivacyStatus] = useState(false)
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [selectedCountry, setSelectedCountry] = useState("");

    const selectCountryHandler = (value) => setSelectedCountry(value);

    // Have to register the languages you want to use
    countries.registerLocale(enLocale);

    // Returns an object not a list
    const countryObj = countries.getNames("en", { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
        label: value,
        value: key
        };
    });

    const [emailVal, setEmailVal] = useState('');
    const [LanguageVal, setLanguage] = useState('');
  const [response, setResponse] = useState('');
  const [isValid, setisValid] = useState('');
  const [isValidName, setisValidName] = useState('');
  const [isValidPrivacy, setisValidPrivacy] = useState('');
  const [isValidLanguage, setisValidLanguage] = useState('');
  const [msgPrivacyResp, setmsgPrivacyResp] = useState('');
  const [msgResponse, setmsgResponse] = useState('');
  const [nameResponse, setNameResponse] = useState('');
  const [languageResponse, setLanguageResponse] = useState('')


  useEffect(() => {
    if (isValidLanguage == 'is-invalid') {
        const searchWrapper = document.querySelector('.searchWrapper');
        if (typeof(searchWrapper) !== 'undefined' && searchWrapper !== null) {
            
            searchWrapper.classList.add(`${isValidLanguage}`);
            searchWrapper.classList.remove(`is-valid`);
        }
    } else if (isValidLanguage == 'is-valid') {
        const searchWrapper = document.querySelector('.searchWrapper');
        if (typeof(searchWrapper) !== 'undefined' && searchWrapper !== null) {
            
            searchWrapper.classList.add(`${isValidLanguage}`);
            searchWrapper.classList.remove(`is-invalid`);

            searchWrapper.addEventListener('click', (e) => {
                setisValidLanguage('')
                searchWrapper.classList.remove(`is-invalid`);
            })
        
        }
    }

}, [isValidLanguage]);

useEffect(() => {
    const searchBox = document.querySelector('.searchBox');
    if (typeof(searchBox) !== 'undefined' && searchBox !== null) {
        searchBox.addEventListener('click', (e) => {
            var el = document.getElementsByClassName('optionListContainer');
            //console.log('el lenght',el.length)
            if (el.length > 0){
                el[0].classList.add('displayBlock');
                el[0].classList.remove('displayNone')
            }
            
        })

    }


}, []);


  

  
  const subscribeForm = async (e) => {


    e.preventDefault();

   /* setTimeout(function() {
      //remove is-invalid class
      setisValid('');
    }, 1000); */
    console.log('language', LanguageVal)

    setisValid('');
    setmsgResponse('')

    var resName = validationInput(name, 'minchars');

    var resEval = validationInput(emailVal, 'email');
    var resLanguageEval = validationInput(LanguageVal, 'array', plainArray)
    var resPrivacyEval = validationInput(privacyStatus, 'bool')
    
    console.log('resEval', resEval);
    console.log('resLanguageEval', resLanguageEval);
    console.log('privacy status', privacyStatus)

    if (!resEval?.success || !resName?.success || !resLanguageEval?.success || !resPrivacyEval?.success) {
    
    (resEval?.class) ? setisValid(resEval?.class) : null;
    (resEval?.message) ? setResponse(resEval?.message) : null;

    (resName?.class) ? setisValidName(resName?.class) : null;
    (resName?.message) ? setNameResponse(resName?.message) : null;

    (resLanguageEval?.class) ? setisValidLanguage(resLanguageEval?.class) : null;
    (resLanguageEval?.message) ? setLanguageResponse(resLanguageEval?.message) : null;

    resPrivacyEval?.class ? setisValidPrivacy(resPrivacyEval?.class) :null
    resPrivacyEval?.message ? setmsgPrivacyResp(resPrivacyEval?.message) :null

    
    
    
    } else {
      //valid email

      var srvRoute = '/se/contact-form';
      var email = emailVal;

      var postData = [{
        email: email,
        name: name,
        language: LanguageVal,
        country: selectedCountry,
        
      }];

     
        let res = await srvConn(srvRoute,postData);
      

       if (res?.success) {
        setResponse("Email correctly sent.");
        var el = document.getElementById("email");
        el?.remove();
        setmsgResponse(`Thanks for your subscription at the email ${emailVal}`);
        var elBtn = document.getElementById("form-contact");
        elBtn?.remove();

        } else {
          console.log("error",res);
          var value_error = (res.error?.includes("E11000 duplicate key")) ? "Email already subscribed" : "There was a problem sending the email";
          setResponse(value_error);
          var elBtn = document.getElementById("form-contact");
         elBtn?.remove();
        }

     

    }

    const bootstrap = require("bootstrap");

    //const toast = new bootstrap.Toast("#liveToast");
    //toast.show();
    
  }
  


  

    return (
        <div className="contact-form drop-shadow-2">
            <div className="contact-form-wrapper">
                <SectionHeading
                    icon='las la-envelope'
                    heading='clock is ticking'
                    subHeading='Pre-registration'
                    additionalClasses='section-heading-2 center'
                />
                <p className='c-dark'>{msgResponse}</p>
                <form onSubmit={subscribeForm} id="form-contact">
                
                    <div className="form-floating">
                   
                        <input 
                        className={`input form-control ${isValidName}`}
                        id="name-field" 
                        type="text" 
                        placeholder="Full name" 
                        value={name}
                        name="name"
                        onChange={(e) => { setName(e.target.value); setisValidName('') }}
                        />
                        <label htmlFor="name-field">Full name</label>
                        <p>{nameResponse}</p>
                    </div>
                    <div className="form-floating">
                        <input 
                        className={`input form-control ${isValid}`}
                        id="email-field" 
                        type="text" 
                        placeholder="Email address" 
                        name="email"
                        onChange={e => {setEmailVal(e.target.value); setisValid('')}}
                        />
                        <label htmlFor="email-field">Email address</label>
                        
                        <p>{response}</p>
                    </div>
                    <div className="form-floating">
                        <select 
                        className="form-select-lg form-control" 
                        id="country-field" 
                        type="select" 
                        placeholder="Country" 
                        value={selectedCountry}
                        onChange={(e) => selectCountryHandler(e.target.value)}
                     
                        >
                
                {!!countryArr?.length &&
                    countryArr.map(({ label, value }) => (
                        <option id="country-field" key={value} value={value}>
                        {label}
                        </option>
                    ))}
                         </select>
                        <label htmlFor="country-field">Country</label>
                    </div>
                    <div className="form-floating">
                    <Multiselect id='language-field' name='language' showArrow options={plainArray} isObject={false} onCloseOptionList={e =>{
                        var el = document.getElementsByClassName('optionListContainer');
                        //console.log('el lenght',el.length)
                        if (el.length > 0){
                            el[0].classList.remove('displayBlock');
                            el[0].classList.add('displayNone')
                        }
                    }} onSelect={e => {setLanguage(e); setisValidLanguage(''); 
                    
                    var el = document.getElementsByClassName('optionListContainer');
                    //console.log('el lenght',el.length)
                    if (el.length > 0){
                        el[0].classList.remove('displayBlock');
                        el[0].classList.add('displayNone')
                    }
                    
                    }} />
                    <label htmlFor="language-field">Language</label>
                    
                    </div>
                    <p>{languageResponse}</p>

                    <div className="form-check form-switch form-floating">
                        <div className={isValidPrivacy}>
                        <input className="form-check-input" type="checkbox" id="privacy" checked={privacyStatus} onClick={ e => { !privacyStatus ? setPrivacyStatus(true) : setPrivacyStatus(false) }}/>
                        <p className="form-check-label" htmlFor="privacy">I Agree to <Link to='/privacy-policy'>Privacy Policy</Link></p></div>
                        <p className=''>{msgPrivacyResp}</p>
                    </div>
                    <button type="submit" className="button button-3">
                        <span className="button-inner">
                            <span className="button-content">
                                <span className="text">Submit Reply</span>
                            </span>
                        </span>
                    </button>
                    
                </form>
            </div>
        </div>
    );
};

export default ContactForm1;
