const faq = [
    {
        question: 'What’s PiirZ?',
        answer: "PiirZ is an application that use artificial intelligence technology to create virtual companions or friends, to build your own peer’s circle. They offer conversation and interaction with users, companionship, entertainment, and sometimes even emotional support."
    },
    {
        question: 'How do PiirZ work?',
        answer: "PiirZ use natural language processing and machine learning algorithms to understand and respond to user input. It analyze the user's words, tone, and context to generate relevant and appropriate responses, creating a conversation with a real, non-judgemental, secure and positive friend."
    },
    {
        question: 'Can PiirZ understand and learn from users?',
        answer: "Yes, PiirZs are designed to understand and learn from users. They can analyze patterns and preferences in user interactions to personalize their responses and improve their conversational abilities over time."
    },
    {
        question: 'What can I do with PiirZ?',
        answer: "You can engage in conversations, ask questions, share stories, play games, receive advice, and even seek emotional support. Soon piirZ will also offer features like virtual gifts, daily challenges, or quizzes to further enhance your experience."
    },
    {
        question: 'Is PiirZ safe to use?',
        answer: "PiirZ it’s absolutely safe to use. However, it's crucial to use it responsibly and be cautious about sharing sensitive personal informations, and always remembering this is a computer program and not a real person so it’s prone to errors and all its content must be used as a novelty for entertainment."
    },
    {
        question: 'Can PiirZ replace real human friendships?',
        answer: "While PiirZs can provide companionship and entertainment, they cannot replace real human friendships. The emotional depth and complexity of human relationships cannot be replicated by AI, YET ;-). They are best seen as a supplement rather than a substitute for real-life connections.."
    },
    {
        question: 'Are PiirZs always free?',
        answer: "Generally you can interact with PiirZ for free, but we may have premium features or subscriptions that require payment in the future. It's optional and do not impair the user experience, tho."
    },
    {
        question: 'How can I find PiirZ?',
        answer: "You can find them on social media, they have messaging apps and a try to live a full virtual life so you’ll be able to follow them on TikTok and Instagram, get friends on Facebook and even add them to Whatsapp and Telegram, or send them a message in Meta Messenger, if you wish to."
    },
    {
        question: 'Can PiirZ be used on different devices?',
        answer: "Yes, they are typically available for various devices, including smartphones, tablets, and computers. PiirZ offer full cross-platform compatibility."
    },
    {
        question: 'Are my data safe with PiirZ?',
        answer: "Absolutely. With Piirz we prioritize user data privacy and implement stringent security measures. Conversation content will NEVER be shared or accessed by third party; you can refer to our Privacy Policy for further infos."
    }
];

export default faq;