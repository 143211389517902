import React from 'react';

import SectionHeading from './SectionHeading';

// import Swiper React components
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation } from 'swiper';
import dataPiirz from '../data/piirz-personas';


const ScreenSliderPiirz = ({name}) => {

    const selectedPiirz = dataPiirz.filter(
        (person) => person.name === name
      )
     // console.log('selectedppl',selectedPiirz)

    return (
        <>
            {/* screen section header - start */}
            <div className="container" >
                <div className="row">
                    <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                        <div className="screen-section-header">
                            <SectionHeading
                                icon='las la-tablet'
                                heading='Piirz discover'
                                subHeading="Piirz look and feel"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                        <div className="screen-slider-navigation slider-navigation">
                            <div className="screen-slider-navigation-prev">
                                <i className="las la-long-arrow-alt-left"></i>
                            </div>
                            <div className="screen-slider-navigation-next">
                                <i className="las la-long-arrow-alt-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* screen section header - end */}
            {/* screen section slider - start */}
            <div className="screen-slider">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={30}
                    navigation={{
                            nextEl: '.screen-slider-navigation-prev',
                            prevEl: '.screen-slider-navigation-next'
                    }}
                    centeredSlides={true}
                    loop={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.8
                        },
                        576: {
                            slidesPerView: 2.4
                        },
                        768: {
                            slidesPerView: 2.8
                        },
                        992: {
                            slidesPerView: 4.8
                        }
                    }}
                >
                    {
                      (typeof selectedPiirz[0].images !== 'undefined' &&
                      selectedPiirz[0].images !== null && selectedPiirz[0].images.length > 0)  ? ( selectedPiirz[0].images.map((element, key) => {
                            return (
                                <SwiperSlide key={key}>
                                    <div className="screen-slide" key={key}>
                                        <figure>
                                            <img key={key} src={element.image} alt={name} />
                                        </figure>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                       ) : (null)}
                </Swiper>
            </div>
            {/* screen section slider - end */}  
        </>
    );
};

export default ScreenSliderPiirz;
