
const validationInput = (input, type, arr=null) => {

  var validClass ='is-valid'
  var invalidClass='is-invalid'
  console.log('input', input)

  switch (type) {
    case 'email':
      const emailRegex = /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      //email not valid
      if (!input.match(emailRegex)) {
        return {success: false, message: 'Email not valid, please enter a valid email address', class:invalidClass}
      } else {
        return {success: true, message:'Email correct', class: validClass}
      }
    case 'minchars' :
      var inputLen = input.length;
      //console.log('inputLen', inputLen);
      if (inputLen <= 3) {
        return { success: false, message: 'Field must be at least four characters long', class:invalidClass}
      } else {
        return { success: true, message:'Name correct', class: validClass}
      }

    case 'array':
      if (arr == null) {
        return { success: false, message: 'not valid data', class:invalidClass}
      } else if (arr.length == 0 || input.length == 0) {
        return { success: false, message: 'Choose at least one option', class:invalidClass}
      } else {
        input.map((element, key) => {
          var bool = arr.some(el => el == element)
          if (!bool) return { success: false, message: 'Choose at least one option', class:invalidClass}
        }
        )

        return { success: true, message:'Language correct', class: validClass}
      
      


      }
    case 'bool':
      var resp = ''
      input == true ? resp = {success:true, message: 'Privacy accepted', class:validClass} : resp = {success: false, message:'Please accept privacy term', class:invalidClass}

      return resp
    default:
      
      
  }


}

export default validationInput;