import React from 'react';

import PageHeader from '../components/PageHeader';
import SectionHeading from '../components/SectionHeading';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';

const Contact = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Pre register'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'Pre register',
                        to: '/pre-register'
                    }
                ]}
            />
            {/* page header - end */}

            {/* contact details - start */}
            <div className="contact-details">
                <div className="contact-details-wrapper">
                    <div className="container">
                        {/* contact details heading - start */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    subHeading='Engage with your AI circle every day, on us'
                                    heading='get in touch'
                                    icon='las la-handshake'
                                    additionalClasses='center width-55'
                                />
                            </div>
                            <div className='row gx-5'>
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className='section-heading center width-75'>
                            <p>Spots are limited, and the clock is ticking. Don't miss your chance to be part of this revolutionary journey. Pre-register now and step into the future of AI friendships. 
Experience the Future of AI Friendships with PIIRZ - Where Your Privacy is Paramount. Dive into a world of genuine AI connections, knowing that your conversations are truly yours. At PIIRZ, we prioritize:</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* contact details heading - end */}
                        {/* contact details row - start */}
                        
                        <div className="row gx-5 details-row">
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                   
                                    <div className="app-feature-single-wrapper">
                                        <div className="icon">
                                            <i className="las la-user-shield"></i>
                                        </div>
                                        <h3 className="c-dark">Absolute Privacy</h3>
                                        <p className="c-grey">
                                            Only you have access to your conversation data. We don't peek, and we don't pry.                                 
                                        </p>
                                       
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                    <div className="app-feature-single-wrapper">
                                        <div className="icon">
                                            <i className="las la-shield-alt"></i>
                                        </div>
                                        <h3 className="c-dark"> No Data Sharing</h3>
                                        <p className="c-grey">
                                        Your trust is invaluable. We pledge never to share or sell your data to third parties.
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                    <div className="app-feature-single-wrapper">
                                        <div className="icon">
                                            <i className="las la-burn"></i>
                                        </div>
                                        <h3 className="c-dark">Exclusive Benefits with Pre-Registration</h3>
                                        <p className="c-grey">Enjoy early access, priority on AI friend choices, and a free daily interaction slot. </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* contact details row - end */}
                    </div>
                </div>
            </div>
            {/* contact details - end */}

            {/* contact form section - start */}
            <div className="contact-form-section">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start */}
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* newsletter form - start */}
                                <ContactForm2/>
                                {/* newsletter form - end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default Contact;
