import React from 'react';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/logo.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-row">
                                <div className="footer-detail">
                                    <Link to="#">
                                        <img src={logo} alt="footer-logo" />
                                    </Link>
                                    <p className="c-grey-1">&copy; 2024 Piirz LTD. All rights reserved. The product, logo and brand "PiirZ" is the exclusive property of Piirz LTD and is protected by UK and international copyright laws.</p>
                                   { /*** <div className="links">
                                        <a className="link-underline" href="mailto:hello@piirz.com">
                                            <span>hello@piirz.com</span>
                                        </a>
                                         <a className="link-underline" href="tel:+1-234-567-890">
                                            <span>+1 234 567 890</span> 
                                        </a>
                                    </div>*/}
                                </div>
                                <div className="footer-list footer-social social-gradient">
                                    <h6>Follow</h6>
                                    <ul>
                                        <li className="twitter">
                                            <Link to="https://twitter.com/piirz_ai" className="link-underline">
                                            <i><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></i>
                                                <span>X account</span>
                                            </Link>
                                        </li>
                                        <li className="discord">
                                            <Link to="https://discord.gg/7k9xWaXy" className="link-underline">
                                                <i className="fab fa-discord"></i>
                                                <span>Discord</span>
                                            </Link>
                                        </li>
                                       <li className="instagram">
                                            <Link to="https://instagram.com/piirz_ai" className="link-underline">
                                                <i className="fab fa-instagram"></i>
                                                <span>Instagram</span>
                                            </Link>
                                        </li>
                                       {/** <li className="linkedin">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-linkedin-in"></i>
                                                <span>Linkedin</span>
                                            </Link>
                                        </li>
                                        <li className="youtube">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-youtube"></i>
                                                <span>Youtube</span>
                                            </Link>
                                        </li> */} 
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Menu</h6>
                                    <ul>
                                        <li>
                                            <Link to="about" className="link-underline">
                                                <span>About</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="piirz" className="link-underline">
                                                <span>The Piirz</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="features-1" className="link-underline">
                                                <span>Features</span>
                                            </Link>
                                        </li>
                                        
                                        <li>
                                            <Link to="about" className="link-underline">
                                                <span>How It Works</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="pre-register" className="link-underline">
                                                <span>Pre-register</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Explore</h6>
                                    <ul>
                                        <li>
                                            <Link to="privacy-policy" className="link-underline">
                                                <span>Terms of Services</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="privacy-policy" className="link-underline">
                                                <span>Privacy Policy</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="privacy-policy" className="link-underline">
                                                <span>Help Center</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-copyright c-grey-1">
                                <h6>&copy; Piirz LTD</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-pattern" style={{backgroundImage: `url(${background_image_1})`}}></div>
            </div>
        </footer>
    );
};

export default Footer;
