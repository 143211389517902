import React, { useState } from 'react';
import SectionHeading from './SectionHeading';
import validationInput from './helpers/validationInput';
import srvConn from './helpers/srvConn';

const ContactForm1 = () => {

    const [email, setEmail] = useState('');

    const [emailVal, setEmailVal] = useState('');
    const [msgResponse, setmsgResponse] = useState('');
    const [isValid, setisValid] = useState('');
    const [response, setResponse] = useState('');
    const [name, setName] = useState('');
    const [isValidName, setisValidName] = useState('');
    const [nameResponse, setNameResponse] = useState('');

    const subscribeNl = async (e) => {

        e.preventDefault();
        setisValid('');
        setmsgResponse('')

        var resEval = validationInput(email, 'email');
        var resName = validationInput(name, 'minchars');



        if (!resEval?.success || !resName?.success) {
            (resEval?.class) ? setisValid(resEval?.class) : null;
            (resEval?.message) ? setResponse(resEval?.message) : null;

            (resName?.class) ? setisValidName(resName?.class) : null;
            (resName?.message) ? setNameResponse(resName?.message) : null;
        } else {
            var srvRoute = '/se/welcome';
           
            var postData = {
              email: email,
              name: name
              
            };
      
           
              let res = await srvConn(srvRoute,postData);
            
      
             if (res?.success) {
              setResponse("Email correctly sent.");
              var el = document.getElementById("email");
              el?.remove();
              setmsgResponse(`Thanks for your subscription at the email ${emailVal}`);
              var elBtn = document.getElementById("form-newsletter");
              elBtn?.remove();
      
              } else {
                console.log("error",res);
                var value_error = (res.error?.includes("E11000 duplicate key")) ? "Email already subscribed" : "There was a problem sending the email";
                setmsgResponse(value_error);

                var elBtn = document.getElementById("form-newsletter");
               elBtn?.remove();
              }
        }

    }

    return (
        <div className="contact-form contact-form-1 drop-shadow-2">
            <div className="contact-form-wrapper">
                <SectionHeading
                    icon='las la-envelope'
                    heading='subscribe'
                    subHeading='Our newsletter'
                    additionalClasses='section-heading-2 center'
                />
                <div className="contact-form-icon">
                    <i className="las la-envelope-open-text"></i>
                </div>
                <p className='c-dark'>{response}</p>
                <p className='c-dark'>{msgResponse}</p>
                <form id="form-newsletter" onSubmit={subscribeNl}>
                <div className={`form-floating`}>
                        <input 
                        className={`input form-control ${isValidName}`}
                        id="name-field" 
                        type="text" 
                        name="name"
                        placeholder="Email address" 
                        onChange={(e) => { setName(e.target.value); setisValidName('') }}
                        />
                        <label htmlFor="name-fiels">Name</label>
                        <p>{nameResponse}</p>
                    </div>
                    <div className={`form-floating`}>
                        <input 
                        className={`input form-control ${isValid}`}
                        id="email-field-1" 
                        type="text" 
                        placeholder="Email address" 
                        name='email'
                        onChange={(e) => { setEmail(e.target.value) }}
                        />
                        <label htmlFor="email-field-1">Email address</label>
                        <p>{response}</p>
                    </div>
                    <button type="submit" className="button button-2">
                        <span className="button-inner">
                            <span className="button-content">
                                <span className="text">Subscribe</span>
                            </span>
                        </span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm1;
