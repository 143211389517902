import React, { useEffect } from 'react';

import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

const VideoLightBox2 = ({URL, color, buttonType}) => {

    useEffect(() => {
        const lightbox = GLightbox({
            skin: 'clean video-lightbox'
        });
        return () => lightbox.destroy();
    }, []);

    return (
    <a href={URL} className={`download-button download-button-apple ${buttonType} glightbox`}>
    <div className={`download-button-inner ${color}`}>
        <div className={`download-button-icon c-red ${color}`}>
            <i className="fa fa-film"></i>
        </div>
        <div className="download-button-content">
            
            <h5 className={`c-grey upper ls-1 ${color}`}>look up</h5>
            
            <h3 className={`c-dark ls-2 ${color}`}>Intro Video</h3>
        </div>
    </div>
</a>
)
};

export default VideoLightBox2;
