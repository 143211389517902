const features = [
    {
        icon: 'las la-users',
        heading: 'Diverse AI Character Library',
        excerpt: 'Piirz offers a vast selection of AI characters from various backgrounds, personalities, and expertise. Users can choose characters that resonate with their needs, ensuring a diverse circle of peers.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-graduation-cap',
        heading: 'Adaptive Learning Mechanism',
        excerpt: 'Each AI character is equipped with a learning mechanism that adapts to the user\'s interaction style and needs. Over time, these characters can provide more personalized advice and insights based on past interactions.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-glass-cheers',
        heading: 'Non-Judging Interaction Environment',
        excerpt: 'The AI characters are programmed to provide feedback, advice, and interactions in a positive, constructive, and non-judgmental manner. This ensures users feel safe and encouraged in their growth journey.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-kiss-wink-heart',
        heading: 'Synthetic Emotions for Authentic Interactions',
        excerpt: 'The AI characters are imbued with "synthetic emotions" that allow them to respond in a manner that feels genuine and relatable. This enhances the quality of interactions, making them feel more human-like and genuine, fostering deeper connections and understanding.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-project-diagram',
        heading: 'Skill and Knowledge Enhancement Modules',
        excerpt: 'AI characters come with specific expertise and knowledge areas. \n \n Users can choose to interact with characters that align with their growth goals, be it learning a new skill, understanding a topic, \nor gaining a new perspective.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-clone',
        heading: 'Emotion Recognition and Response',
        excerpt: 'Piirz can detect the user\'s emotional state through text or voice inputs and adjust the AI character\'s responses accordingly. \n\n This ensures that interactions are not only informative but \n also emotionally supportive.',
        to: '/feature-detail-1'
    }
];

export default features;