
import {React} from 'react';
import PageHeader from '../components/PageHeader';
import ScreenSlider from '../components/ScreenSlider';
import BackgroundAnimation from '../components/BackgroundAnimation';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import dataPiirz from '../data/piirz-personas';
import parse from 'html-react-parser';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';
import ScreenSliderPiirz from '../components/ScreenSliderPiirz';

const PiirzSingle = ({name}) => {

  const selectedPiirz = dataPiirz.filter(
    (person) => person.name === name
  )
  //console.log('selectedppl',selectedPiirz)
  
  return( <div key={name}>
  {/* page header - start */}
  <PageHeader
      title={name}
      pages={[
          {
              title: 'Home',
              to: '/'
          },
          {
              title: name,
              to: `/${name}`
          }
      ]}
  />
  {/* page header - end */}
    {/* about section - start */}
    <div className="about-section" >
                <div className="about-section-wrapper">
                    <div className="container">
                        {/* first half - start */}
                       
                                          { selectedPiirz.map((el, key )=> { return (
                        <div className="row" key={key}>
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="about-section-content c-grey">
                                    <div className="section-heading">
                                        <div className="sub-heading c-blue upper ls-1">
                                            <i className="las la-user-circle"></i>
                                            <h5>introduction</h5>
                                        </div>
                                        <div className="main-heading c-dark" key={key}>
                                            <h1>{el.name}</h1>
                                        </div>
                                    </div>
                                    <p className="paragraph-big">{el.designation}</p>
                                    <p>{parse(el.description)}</p>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                                <div className="about-section-image">
                                    <div className="pattern-image pattern-image-1">
                                        <div className="pattern-image-wrapper">
                                        {
                       (typeof el.images !== 'undefined' &&
                       el.images !== null && el.images.length > 0)  ? (<img key={key} className="drop-shadow-1" src={el.images[0].image} height='450' alt="pattern" />) : null }
                                          
                                          <div className="background-pattern background-pattern-radius drop-shadow-1">
                                                <BackgroundAnimation/>
                                                <div className="background-pattern-gradient"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)})}
                                            
                                            
                        {/* first half - end */}
                       
                        
                    </div>
                </div>
    </div>
            {/* about section - end */}
  {/* screen section - start */}
  <div className="screen-section" key={name}>
                <div className="screen-section-wrapper" key={name}>
                    <ScreenSliderPiirz name={name}/>
                    {/* screen section bottom - start */}
                    <div className="screen-section-bottom">
                        <div className="screen-section-bottom-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                                        <h1 className="c-white">Soon on your favorite messaging app!</h1>
                                        <h4 className="c-white">
                                            <i className="fas fa-smile" style={{marginRight: '1rem'}}></i>
                                            Piirz: Elegant, Engaging, Exceptional!
                                        </h4>
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* screen section bottom - end */}     
                </div>
                <div className="background-pattern background-pattern-2">
                    <BackgroundAnimation/>
                    <div className="background-pattern-gradient"></div>
                    <div className="background-pattern-bottom">
                        <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                    </div>
                </div>
            </div>
            {/* screem section - end */}
             {/* instagram - start */}
             <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Soon on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
  </div>
  
  )
}

export default PiirzSingle;